import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Chatbot from './ChatBot';
import ParameterBot from './ParameterBot';
import SalesBot from './SalesBot';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function MainContent() {
    const query = useQuery();
    const location = useLocation(); 
    
    const [isChatVisible, setIsChatVisible] = useState(false);
    const [isParameterVisible, setIsParameterVisible] = useState(false);
    const [isSalesVisible, setIsSalesVisible] = useState(false);


    useEffect(() => {
        const agentType = query.get("agent_type");
    
        // Reset visibility states
        setIsChatVisible(false);
        setIsParameterVisible(false);
        setIsSalesVisible(false);
    
        // Show the correct bot based on agent type
        if (agentType === "parameter") {
            setIsParameterVisible(true);
        } else if (agentType === "sales") {
            setIsSalesVisible(true);
        } else if (agentType === "sub") {
            setIsChatVisible(true);
        }
    
    }, [location.search]);  // Only depend on location.search (query params)

    return (
        <>
            {isParameterVisible && (
                <ParameterBot
                    onClose={() => setIsParameterVisible(false)}
                    isVisible={isParameterVisible}
                />
            )}
            {isSalesVisible && (
                <SalesBot
                    isVisible={isSalesVisible}
                    onClose={() => setIsSalesVisible(false)}
                    agent_type={"sales"}
                />
            )}
            {isChatVisible && (
                <Chatbot
                    isVisible={isChatVisible}
                    onClose={() => setIsChatVisible(false)}
                    agent_type={"betting"}
                />
            )}
        </>
    );
}

const App = () => {
    return (
        <Router>
            <MainContent />
        </Router>
    );
};

export default App;