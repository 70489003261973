import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    TextField,
    Paper,
    Typography,
    Grid,
    Box,
    CircularProgress,
    IconButton,
    useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import { marked } from 'marked';

const ChatContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '90vh',
    backgroundColor: 'white',
    borderRadius: theme.spacing(2),
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.grey[200]}`,
    display: 'flex',
    flexDirection: 'column'
}));

const Header = styled(Box)(({ theme }) => ({
    background: 'linear-gradient(90deg, #1a1a1a 0%, #2d2d2d 100%)',
    padding: '8px',
    paddingBottom: '2px',
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    flexShrink: 0
}));

const MessageContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing(2),
    height: 'calc(90vh - 140px)',
    backgroundColor: theme.palette.grey[50],
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-track': {
        background: theme.palette.grey[100],
        borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: theme.palette.grey[300],
        borderRadius: '4px',
        '&:hover': {
            background: theme.palette.grey[400],
        }
    }
}));

const MessageBubble = styled(Paper)(({ isUser, theme }) => ({
    padding: theme.spacing(1.5, 2),
    marginBottom: theme.spacing(1.5),
    maxWidth: '75%',
    wordWrap: 'break-word',
    backgroundColor: isUser ? '#ff9800' : '#ffffff',
    color: isUser ? '#ffffff' : theme.palette.text.primary,
    borderRadius: isUser ? '16px 16px 4px 16px' : '16px 16px 16px 4px',
    boxShadow: isUser ? '0 2px 8px rgba(255,152,0,0.15)' : '0 2px 8px rgba(0,0,0,0.08)',
    border: isUser ? 'none' : `1px solid ${theme.palette.grey[100]}`,
    fontSize: '1rem',
    lineHeight: '1.5',
    '& p': {
        margin: '0.5em 0',
        '&:first-child': {
            marginTop: 0
        },
        '&:last-child': {
            marginBottom: 0
        }
    },
    '& a': {
        color: isUser ? '#ffffff' : '#ff9800',
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    '& code': {
        backgroundColor: isUser ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
        padding: '2px 4px',
        borderRadius: '4px',
        fontSize: '0.9em'
    },
    '& pre': {
        backgroundColor: isUser ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
        padding: theme.spacing(1),
        borderRadius: '4px',
        overflow: 'auto'
    }
}));

const InputContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: 'white',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    flexShrink: 0
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#f8f9fa',
        borderRadius: '24px',
        transition: 'all 0.2s ease',
        '& fieldset': {
            borderColor: 'transparent',
            borderWidth: '1.5px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '12px 18px',
            fontSize: '1rem',
        },
        '&:hover': {
            backgroundColor: '#f0f2f5',
            '& fieldset': {
                borderColor: 'transparent',
            }
        },
        '&.Mui-focused': {
            backgroundColor: '#ffffff',
            '& fieldset': {
                borderColor: '#ff9800',
                borderWidth: '1.5px',
            }
        },
    }
}));

const Chatbot = ({ isVisible, onClose, agent_type }) => {
    const theme = useTheme();
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const [input, setInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const decoder = new TextDecoder();
    const messagesEndRef = useRef(null);
    const abortControllerRef = useRef(null);

    const scrollToBottom = useCallback(() => {
        if (messagesEndRef.current) {
            requestAnimationFrame(() => {
                messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
            });
        }
    }, []);

    const setSessionIdAndStartConversation = useCallback(async () => {
        try {
            setIsLoading(true);
            const sessionResponse = await fetch("/start_session/", {
                method: "POST",
                credentials: "include",
                signal: abortControllerRef.current?.signal
            });
            const sessionData = await sessionResponse.json();
            localStorage.setItem("session_id", sessionData.session_id);
            
            setMessages(prevMessages => [...prevMessages, 
                { text: "Please provide your name, email, and phone number so I can register you for our betting tips, newsletters, and news.", from: "bot" }
            ]);
        } catch (error) {
            if (!error.name === 'AbortError') {
                console.error("Error:", error);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        let mounted = true;
        abortControllerRef.current = new AbortController();
        
        marked.setOptions({
            breaks: true,
            gfm: true,
            sanitize: true
        });

        const initializeChat = async () => {
            if (mounted) {
                await setSessionIdAndStartConversation();
            }
        };

        initializeChat();

        return () => {
            mounted = false;
            abortControllerRef.current?.abort();
        };
    }, [setSessionIdAndStartConversation]);

    useEffect(() => {
        const timeoutId = setTimeout(scrollToBottom, 100);
        return () => clearTimeout(timeoutId);
    }, [messages, currentMessage, scrollToBottom]);

    useEffect(() => {
        const handleEnter = (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                sendMessage();
            }
        };

        const inputElement = document.querySelector('input');
        if (inputElement) {
            inputElement.addEventListener('keypress', handleEnter);
            return () => inputElement.removeEventListener('keypress', handleEnter);
        }
    }, [input, isLoading]);

    const sendMessage = async () => {
        if (!input.trim() || isLoading) return;

        let reader;
        try {
            const userMessage = input;
            setInput("");
            setIsLoading(true);
            
            setMessages(prevMessages => [...prevMessages, 
                { text: userMessage, from: "user" }
            ]);

            abortControllerRef.current = new AbortController();
            const response = await fetch("/query/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    query: userMessage,
                    thread_id: localStorage.getItem("session_id"),
                    agent_type: agent_type
                }),
                signal: abortControllerRef.current.signal
            });
            
            let combinedStr = "";
            reader = response.body.getReader();
            
            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                if (value) {
                    const chunkStr = decoder.decode(value, { stream: true });
                    combinedStr += chunkStr;
                    setCurrentMessage(prevMessage => prevMessage + chunkStr);
                }
            }

            setMessages(prevMessages => [...prevMessages, 
                { text: combinedStr, from: "bot" }
            ]);
            setCurrentMessage("");
        } catch (error) {
            if (!error.name === 'AbortError') {
                console.error("Fetch error:", error);
            }
        } finally {
            setIsLoading(false);
            if (reader) {
                reader.cancel();
            }
        }
    };

    return (
        <ChatContainer>
            <Header>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Box
                            component="img"
                            src="/images/max_image.jpeg"
                            alt="Max"
                            sx={{
                                width: 100,
                                height: 100,
                                borderRadius: '16px',
                                border: '3px solid rgba(255,255,255,0.25)',
                                boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                                marginLeft: '-8px',
                                marginTop: '-8px',
                                marginBottom: '-8px'
                            }}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography
                            variant="h6"
                            sx={{
                                color: 'white',
                                fontWeight: 700,
                                fontSize: '1.5rem',
                                letterSpacing: '0.2px',
                                lineHeight: '1.2',
                                marginBottom: '4px'
                            }}
                        >
                            Max
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: 'rgba(255,255,255,0.7)',
                                fontSize: '1rem',
                                marginTop: '2px'
                            }}
                        >
                            Subscriber Agent
                        </Typography>
                    </Grid>
                </Grid>
            </Header>

            <MessageContainer ref={messagesEndRef}>
                {messages.map((msg, index) => (
                    <Box
                        key={`${index}-${msg.from}`}
                        sx={{
                            display: 'flex',
                            justifyContent: msg.from === "user" ? 'flex-end' : 'flex-start',
                            mb: 1.5
                        }}
                    >
                        <MessageBubble 
                            isUser={msg.from === "user"}
                            dangerouslySetInnerHTML={{ 
                                __html: marked(msg.text)
                            }}
                        />
                    </Box>
                ))}
                {currentMessage && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 1.5 }}>
                        <MessageBubble
                            dangerouslySetInnerHTML={{ 
                                __html: marked(currentMessage)
                            }}
                        />
                    </Box>
                )}
                {isLoading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                        <CircularProgress
                            size={24}
                            thickness={4}
                            sx={{ color: '#ff9800' }}
                        />
                    </Box>
                )}
            </MessageContainer>

            <InputContainer>
                <Grid container spacing={1.5} alignItems="center">
                    <Grid item xs>
                        <StyledTextField
                            fullWidth
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Type your message..."
                            size="small"
                            variant="outlined"
                            disabled={isLoading}
                            multiline
                            maxRows={4}
                        />
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={sendMessage}
                            disabled={!input.trim() || isLoading}
                            sx={{
                                backgroundColor: '#ff9800',
                                color: 'white',
                                width: '42px',
                                height: '42px',
                                transition: 'all 0.2s ease',
                                '&:hover': {
                                    backgroundColor: '#f57c00',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#e0e0e0',
                                    color: 'rgba(0, 0, 0, 0.26)'
                                }
                            }}
                        >
                            <SendIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </InputContainer>
        </ChatContainer>
    );
};

export default Chatbot;